import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import useIdle from './useIdle';

const IdleAware: FunctionComponent = ({ children }) => {
  const idle = useIdle({ timeToIdle: 5e3, ignoredEventsWhenIdle: [] });

  return <IdleAwareWrapper show={!idle}>{children}</IdleAwareWrapper>;
};

export default IdleAware;

interface IdleAwareWrapperProps {
  readonly show: boolean;
}

const IdleAwareWrapper = styled.span<IdleAwareWrapperProps>`
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.2s ease-out;
`;
