import { Color as CSSColor } from 'csstype';

export type Color = CSSColor;

export function randomColor(): Color {
  // Generally dark.
  return `hsl(${~~(Math.random() * 359)}, 40%, 25%)`;
}

// https://stackoverflow.com/a/35970186
export function contrast(color: Color): Color {
  if (color.indexOf('#') === 0) {
    color = color.slice(1);
  }

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }
  if (color.length !== 6) {
    return 'white';
  }
  var r = parseInt(color.slice(0, 2), 16),
    g = parseInt(color.slice(2, 4), 16),
    b = parseInt(color.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 100 ? '#000000' : '#FFFFFF';
}
