import React, { FunctionComponent, useContext, useState } from 'react';
import Countdown, { renderDuration } from 'react-round-countdown';
import styled from 'styled-components';

import { TimerConfig, Duration } from './domain/timers.reducer';
import Emoji from './Emoji';
import TimersContext from './TimersContext';
import { contrast } from './domain/colors';
import IdleAware from './IdleAware';

const timerFontSize = '3.5em';

function parseDuration(str: string): Duration | null {
  try {
    const [minutes, seconds = 0] = str.split(':').map(p => parseInt(p));
    return (minutes * 60 + seconds) * 1000;
  } catch (e) {
    console.log(`Trying to parse ${str}`, e);
    return null;
  }
}

const StartTimer: FunctionComponent = () => {
  const {
    timer: { duration, color },
    despacha
  } = useContext(TimersContext);
  const [value, setValue] = useState(renderDuration(duration));
  const [editing, setEditing] = useState(false);

  const commit = () => {
    setEditing(false);
    const newDuration = parseDuration(value);
    if (newDuration) {
      despacha({ tipo: 'change duration', duration: newDuration });
    }
  };

  if (editing) {
    return (
      <>
        <Input
          type="text"
          pattern="((0\d)|([1-9]\d)):((0\d)|([1-9]\d))"
          title="Round timer in MM:SS format"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={() => commit()}
          onKeyPress={({ key }) => key === 'Enter' && commit()}
        />
      </>
    );
  }

  return (
    <Editable onClick={() => setEditing(true)}>
      <ConColor color={contrast(color)}>{renderDuration(duration)}</ConColor>
      <Edit>
        <IdleAware>
          <Emoji simbolo="✏️" label="Edit timer" />
        </IdleAware>
      </Edit>
    </Editable>
  );
};

const Timer: FunctionComponent<TimerConfig> = ({ end, paused }) => {
  const {
    timer: { color }
  } = useContext(TimersContext);

  const contrastColor = contrast(color);
  return (
    <>
      {paused ? (
        <TimerWrapper>
          <StartTimer />
        </TimerWrapper>
      ) : (
        <Countdown
          colors={{ normal: contrastColor }}
          end={end}
          style={{
            margin: 0,
            fontSize: timerFontSize,
            WebkitTextStrokeWidth: 5,
            WebkitTextStrokeColor: contrastColor
          }}
        />
      )}
    </>
  );
};

export default Timer;

const TimerWrapper = styled.div`
  cursor: pointer;
`;

const Editable = styled.span`
  font-size: ${timerFontSize};
`;

const Edit = styled.sup`
  display: inline-block;
  width: 0px;
  font-size: 2vh;
`;

const ConColor = styled.span<{ color: string }>`
  color: ${props => props.color};
`;

const Input = styled.input`
  font-size: ${timerFontSize};
  margin: 0;
  font-family: Eczar, serif;
  text-align: center;
  color: black;
  width: calc(100% - 2em);
  height: 1em;
`;
