import React, { FunctionComponent, useContext, useState } from 'react';
import TimersContext from './TimersContext';
import Emoji from './Emoji';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import IdleAware from './IdleAware';

const ColorSelector: FunctionComponent = () => {
  const {
    timer: { color },
    despacha
  } = useContext(TimersContext);

  const [open, setOpen] = useState(false);

  return (
    <IdleAware>
      <ColorSelectorWrapper>
        <Emoji
          onClick={() => setOpen(o => !o)}
          label="Change color"
          simbolo="🌈"
        />
        {open && (
          <>
            <Cover onClick={() => setOpen(false)} />
            <PickerWrapper>
              <ChromePicker
                disableAlpha
                color={color}
                onChange={({ hex }) =>
                  despacha({ tipo: 'change color', color: hex })
                }
              />
            </PickerWrapper>
          </>
        )}
      </ColorSelectorWrapper>
    </IdleAware>
  );
};

export default ColorSelector;

const ColorSelectorWrapper = styled.span`
  cursor: pointer;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PickerWrapper = styled.div`
  position: absolute;
  right: 50%;
`;
