import { useState } from 'react';

const VERSION = 1;

export default function useStorage<T>(
  key: string,
  initialValue: T,
  normalizar: (x: T) => T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const store = window.localStorage.getItem(key);
      const { v, ...item } = store && JSON.parse(store);
      return item && v === VERSION ? normalizar(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: React.SetStateAction<T>) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(
        key,
        JSON.stringify({ v: VERSION, ...valueToStore })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}
