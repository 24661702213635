import { useEffect, useState } from 'react';
import createActivityDetector, { Opts } from 'activity-detector';

export default function useIdle(opt: Opts) {
  const [idle, setIdle] = useState(false);
  const activityDetector = createActivityDetector(opt);

  useEffect(() => {
    activityDetector.on('idle', () => setIdle(true));
    activityDetector.on('active', () => setIdle(false));
  });

  return idle;
}
