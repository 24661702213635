import React, { FunctionComponent } from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import Timers from './Timers';
import Footer from './Footer';

const App: FunctionComponent = () => {
  return (
    <AppWrapper>
      <GlobalStyle />
      <Timers />
      <Footer />
    </AppWrapper>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
  body, html {
    padding: 0;
    margin: 0;
    font-family: Lato, sans-serif;
    overflow: hidden;
  }
`;

const AppWrapper = styled.div`
  text-align: center;
`;
