import React, { FunctionComponent, HTMLAttributes } from 'react';

type EmojiProp = {
  label?: string;
  simbolo: string;
} & HTMLAttributes<Element>;

const Emoji: FunctionComponent<EmojiProp> = ({ label, simbolo, ...rest }) => {
  return (
    <span role="img" {...rest} title={label} aria-label={label || 'emoji'}>
      {simbolo}
    </span>
  );
};

export default Emoji;
