import React, { FunctionComponent, useContext } from 'react';
import TimersContext from './TimersContext';
import Emoji from './Emoji';
import styled from 'styled-components';
import IdleAware from './IdleAware';

const Control: FunctionComponent = () => {
  const {
    timer: { paused },
    despacha
  } = useContext(TimersContext);

  if (paused)
    return (
      <ControlWrapper>
        <IdleAware>
          <Emoji
            onClick={() => despacha({ tipo: 'play' })}
            label="Play"
            simbolo="▶️"
          />
        </IdleAware>
      </ControlWrapper>
    );

  return (
    <ControlWrapper>
      <Emoji
        onClick={() => despacha({ tipo: 'resetear' })}
        label="Reset"
        simbolo="🔁"
      />
    </ControlWrapper>
  );
};

export default Control;

const ControlWrapper = styled.span`
  cursor: pointer;
`;
