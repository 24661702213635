import { randomColor, Color } from './colors';

const defaultTimer = 50 * 60 * 1000 + 1; // 50 minutes

export type Duration = number;
export type TimerConfig = {
  id: string;
  name: string;
  end: Duration;
  duration: Duration;
  paused: boolean;
  color: Color;
};

function id(): string {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);
}

export function titulo() {
  return '';
}

export function timer(): TimerConfig {
  return {
    id: id(),
    name: titulo(),
    end: 0,
    duration: defaultTimer,
    paused: true,
    color: randomColor(),
  };
}

export type AccionIndividual =
  | { tipo: 'play' }
  | { tipo: 'resetear' }
  | { tipo: 'change color'; color: Color }
  | { tipo: 'change duration'; duration: Duration }
  | { tipo: 'renombrar'; nombre: string };

export type Accion =
  | { tipo: 'agregar' }
  | { tipo: 'borrar'; id: string }
  | { tipo: 'individual'; id: string; accion: AccionIndividual };

type Estado = {
  timers: TimerConfig[];
};

export const estadoInicial: Estado = {
  timers: [timer()],
};

const cambiar = (
  estado: Estado,
  id: string,
  cambio: (x: TimerConfig) => Partial<TimerConfig>
): Estado => {
  return {
    ...estado,
    timers: estado.timers.map((timer) =>
      timer.id === id ? { ...timer, ...cambio(timer) } : timer
    ),
  };
};

export const normalizar = (estado: Estado): Estado => {
  const staleTimerEnd = Date.now() - 12 * 60 * 60 * 1000; // 12 horas de terminado
  const nuevoTimers = estado.timers.filter(
    (timer) => timer.end > staleTimerEnd
  );

  if (nuevoTimers.length === 0) {
    return estadoInicial;
  }

  return {
    ...estado,
    timers: nuevoTimers,
  };
};

export const reducer = (estado: Estado, accion: Accion): Estado => {
  switch (accion.tipo) {
    case 'agregar':
      return { ...estado, timers: estado.timers.concat(timer()) };
    case 'borrar':
      if (estado.timers.length > 1)
        return {
          ...estado,
          timers: estado.timers.filter((t) => t.id !== accion.id),
        };
      return estado;
    case 'individual':
      return cambiar(estado, accion.id, reduceUno(accion.accion));
  }
};

const reduceUno =
  (accion: AccionIndividual) =>
  (estado: TimerConfig): Partial<TimerConfig> => {
    switch (accion.tipo) {
      case 'play':
        return { end: estado.duration + Date.now(), paused: false };
      case 'resetear':
        return { paused: true };
      case 'renombrar':
        return { name: accion.nombre };
      case 'change color':
        return { color: accion.color };
      case 'change duration':
        return { duration: accion.duration };
    }
  };

export default reducer;
