import React, { FunctionComponent, useContext, useState } from 'react';
import styled from 'styled-components';
import TimersContext from './TimersContext';
import { TimerConfig } from './domain/timers.reducer';
import Emoji from './Emoji';
import IdleAware from './IdleAware';
import { contrast } from './domain/colors';

const TituloEditable: FunctionComponent<{ timer: TimerConfig }> = ({
  timer: { name, color }
}) => {
  const { despacha } = useContext(TimersContext);
  const [valor, setValor] = useState(name);
  const [editando, setEditando] = useState(false);

  const commit = () => {
    setEditando(false);
    despacha({ tipo: 'renombrar', nombre: valor });
  };

  if (editando) {
    return (
      <>
        <Input
          type="text"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          value={valor}
          onChange={e => setValor(e.target.value)}
          onBlur={() => commit()}
          onKeyPress={({ key }) => key === 'Enter' && commit()}
        />
      </>
    );
  }

  return (
    <>
      <Titulo onClick={() => setEditando(true)}>
        <ConColor color={contrast(color)}>{name}</ConColor>
        <Edit>
          <IdleAware>
            <Emoji simbolo="✏️" label="Edit title" />
          </IdleAware>
        </Edit>
      </Titulo>
    </>
  );
};

export default TituloEditable;

const ConColor = styled.span<{ color: string }>`
  color: ${props => props.color};
`;

const Titulo = styled.h1`
  font-size: 1.3em;
  margin: 0;
  font-family: Eczar, serif;
  line-height: 1;
  cursor: pointer;
  width: 100%;
  height: 1em;
`;

const Edit = styled.sup`
  display: inline-block;
  width: 0px;
  font-size: 2vh;
`;

const Input = styled.input`
  font-size: 1.3em;
  margin: 0;
  font-family: Eczar, serif;
  text-align: center;
  color: black;
  width: calc(100% - 2em);
  height: 1em;
`;
