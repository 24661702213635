import React, { FunctionComponent, useReducer, useEffect } from 'react';

import styled from 'styled-components';

import Timer from './Timer';
import useStorage from './useStorage';
import {
  reducer,
  estadoInicial,
  AccionIndividual,
  TimerConfig,
  normalizar
} from './domain/timers.reducer';
import TimerContext from './TimersContext';
import TituloEditable from './TituloEditable';
import Control from './Control';
import Emoji from './Emoji';
import ColorSelector from './ColorSelector';
import IdleAware from './IdleAware';

const Timers: FunctionComponent = () => {
  const [estadoGuardado, guardar] = useStorage(
    'estado-de-timers',
    estadoInicial,
    normalizar
  );
  const [estado, despacha] = useReducer(reducer, estadoGuardado);
  const {
    timers: [firstTimer, ...restTimers]
  } = estado;

  useEffect(() => {
    guardar(estado);
  }, [guardar, estado]);

  const renderTimer = (timer: TimerConfig, cerrable = true) => {
    const { id, color } = timer;
    const despachaUno = (accion: AccionIndividual) =>
      despacha({ accion, id, tipo: 'individual' });

    const Cerrar = () => (
      <IdleAware>
        <CerrarWrapper>
          {cerrable && (
            <Emoji
              onClick={() => despacha({ tipo: 'borrar', id })}
              label="Close"
              simbolo="❌"
            />
          )}
        </CerrarWrapper>
      </IdleAware>
    );

    return (
      <TimerWrapper key={`timer-${id}`} bg={color}>
        <TimerContext.Provider value={{ timer, despacha: despachaUno }}>
          <TituloEditable timer={timer} />
          <Timer {...timer} />
          <TopLeft>
            <ColorSelector />
            <Control />
            <Cerrar />
          </TopLeft>
        </TimerContext.Provider>
      </TimerWrapper>
    );
  };

  return (
    <>
      <TimersWrapper>
        <FirstTimer>
          {renderTimer(firstTimer, restTimers.length >= 1)}
        </FirstTimer>
        {restTimers.length >= 1 && (
          <ExtraTimersWrapper>
            {restTimers.map(t => renderTimer(t))}
          </ExtraTimersWrapper>
        )}
      </TimersWrapper>
      <IdleAware>
        <FAB onClick={() => despacha({ tipo: 'agregar' })}>
          <Emoji aria-label="Add new timer" simbolo="➕" />
        </FAB>
      </IdleAware>
    </>
  );
};

export default Timers;

const FAB = styled.div`
  position: absolute;
  bottom: 2vh;
  right: 2vw;
  background: #ffeb3b;
  padding: 1vh;
  font-size: 4vh;
  border-radius: 100px;
  cursor: pointer;
`;

const TimersWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const FirstTimer = styled.div`
  flex: 1;
  display: flex;
  min-height: 50%;
`;

const ExtraTimersWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`;

const TimerWrapper = styled.div<{ bg: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  font-size: calc(26px + 4vh);

  background: ${props => props.bg || 'black'};
`;

const TopLeft = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 2vh;
  display: flex;
`;

const CerrarWrapper = styled.span`
  cursor: pointer;
`;
