import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import Emoji from './Emoji';

const Link: FunctionComponent = () => {
  return (
    <a
      href="https://florius.com.ar"
      target="_blank"
      rel="noreferrer"
      style={{ color: 'unset' }}
    >
      Florius.com.ar
    </a>
  );
};

const Footer: FunctionComponent = () => {
  return (
    <>
      <FooterWrapper>
        <Emoji label="love" simbolo="♥" /> by <Link>Florius</Link>.
      </FooterWrapper>
    </>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0.5em;
  left: 1em;
  color: #ffffff7d;
  font-size: 2vh;
`;
